import _ from 'lodash'
import React, { useEffect, useLayoutEffect, useState, useCallback } from 'react'
import DepositExpanded from './client/tables/expanded/DepositExpanded'
import WithdrawalExpanded from './client/tables/expanded/WithdrawalExpanded'
import PaymentOutExpanded from './client/tables/expanded/PaymentOutExpanded'
import TransferExpanded from './client/tables/expanded/TransferExpanded'
import FxExpanded from './client/tables/expanded/FxExpanded'
import AccountsExpanded from './client/tables/expanded/AccountsExpanded'
import { useSelector, useDispatch } from 'react-redux'
import * as actions from '../Redux/actions'
import { format } from 'date-fns'
import { apiService } from '../common/apiCallService'
import BuyCryptoExpanded from './client/tables/expanded/BuyCryptoExpanded'
import SellCryptoExpanded from './client/tables/expanded/SellCryptoExpanded'
import ExchangeExpanded from './client/tables/expanded/ExchangeExpanded'
import SendExpanded from './client/tables/expanded/SendExpanded'
import ReceiveExpanded from './client/tables/expanded/ReceiveExpanded'
import Icon from './client/icons/Icon'
import { setErrorMessage } from '../Redux/actions/junoAction'

export function getCurrencySymbol(shortName) {
    const value = shortName ? shortName.toLowerCase() : ''
    switch (value) {
        case 'eur':
            return '€'
        case 'usd':
            return '$'
        case 'gbp':
            return '£'
        case 'aud':
            return 'A$'
        case 'cad':
            return 'C$'
        case 'aed':
            return 'د.إ'
        case 'chf':
            return '₣'
        case 'try':
            return '₺'
        case 'hkd':
            return 'HK$'
        case 'sgd':
            return 'S$'
        case 'jpy':
            return '¥'
        case 'cny':
            return 'CN¥'
        case 'mxn':
            return 'MX$'
        case 'thb':
            return '฿'
        case 'ils':
            return '₪'
        case 'btc':
            return '₿'
        case 'eth':
            return 'Ξ'
        case 'dai':
            return 'DAI'
        case 'usdt':
            return 'USD₮'
        case 'usdc':
            return 'USDC'
        case 'trx':
            return 'TRX'
        case 'ltc':
            return 'Ł'
        case 'xrp':
            return 'XRP'
        case 'all':
            return '$'
        default:
            return false
    }
}

export const filterBalances = (balances) => {
    const excludedCurrencies = ['TRC20-USDT', 'ERC20-USDT'];
    return balances?.filter(balance => !excludedCurrencies.includes(balance.currencyShortName));
};


export const useOutsideClick = (ref, callback) => {
    const handleClick = e => {
        if (ref.current && !ref.current.contains(e.target)) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    });
};

export const fiatCurrencyList = [
    {
        text: 'EUR',
        fullForm: 'Euro',
        img: '/common/crytoicons/euro.png',
    },
    {
        text: 'GBP',
        fullForm: 'Great British Pound',
        img: '/common/crytoicons/uk.png',
    },
    {
        text: 'USD',
        fullForm: 'United States Dollar',
        img: '/common/crytoicons/usa.png',
    },
    {
        text: 'AED',
        fullForm: 'United Arab Emirates Dirham',
        img: '/common/crytoicons/aed.png',
    },
    {
        text: 'CAD',
        fullForm: 'Canadian Dollar',
        img: '/common/crytoicons/cad.png',
    },
    {
        text: 'AUD',
        fullForm: 'Australian Dollar',
        img: '/common/crytoicons/aud.png',
    },
    {
        text: 'CHF',
        fullForm: 'Swiss Franc',
        img: '/common/crytoicons/chf.png',
    },
    {
        text: 'TRY',
        fullForm: 'Turkish Lira',
        img: '/common/crytoicons/try.png',
    },
    {
        text: 'HKD',
        fullForm: 'Hong Kong Dollar',
        img: '/common/crytoicons/hkd.png',
    },
    {
        text: 'SGD',
        fullForm: 'Singapore Dollar',
        img: '/common/crytoicons/sgd.png',
    },
    {
        text: 'JPY',
        fullForm: 'Japanese Yen',
        img: "/common/crytoicons/jpy.png",
    },
    {
        text: 'CNY',
        fullForm: 'Chinese Yuan',
        img: "/common/crytoicons/cny.png",
    },
    {
        text: 'MXN',
        fullForm: 'Mexican Peso',
        img: "/common/crytoicons/mxn.png",
    },
    {
        text: 'THB',
        fullForm: 'Thai Baht',
        img: "/common/crytoicons/thb.png",
    },
    {
        text: 'ILS',
        fullForm: 'Israeli Shekel',
        img: "/common/crytoicons/ils.png",
    },
];
export function getStatus(statusName) {
    switch (statusName) {
        case 'PrivateClient:TransactionCompleted':
        case 'PrivateClient:AMLHold':
        case 'PrivateClient:AMLApproved':
            return 'Success'
        case 'PrivateClient:RequestForApproval':
        case "PrivateClient:ComplyAdvantageResponse":
        case "PrivateClient:AMLRejected":
            return 'Pending'
        case 'PrivateClient:CreateTransaction':
            return 'Created'
        case 'PrivateClient:Rejected':
            return 'Rejected'
        case 'PrivateClient:ErrorEncountered':
            return 'Error'
        case 'PrivateClient:ClientBalanceUpdated':
            return 'Updated'
        case 'PrivateClient:Processing':
            return 'Processing'
        default:
            return 'N/A'
    }
}

export function getStatusCodes(status) {
    switch (status) {
        case 'Success':
            return [
                'PrivateClient:TransactionCompleted',
                'BuyFlow:BuyCompleted',
                'SellFlow:SellCompleted',
                'SendFlow:SendCompleted',
                'ReceiveFlow:ReceiveCompleted',
                'ExchangeFlow:ExchangeCompleted',
            ];
        case 'Pending':
            return [
                'PrivateClient:RequestForApproval',
                "PrivateClient:ComplyAdvantageResponse",
                "PrivateClient:AMLHold",
                "PrivateClient:AMLApproved",
                "PrivateClient:AMLRejected"
            ];
        case 'Processing':
            return [
                "PrivateClient:Processing",
                "PrivateClient:CreateTransaction",
                "PrivateClient:TransactionApprovedBy",
                "PrivateClient:ClientBalanceUpdated",
                "PrivateClient:DepositDetail",
                "PrivateClient:FXDetail",
                "PrivateClient:PaymentOutDetail",
                "PrivateClient:TransferDetail",
                "PrivateClient:WithdrawalDetail",
                "BuyFlow:InitiateRequest",
                "BuyFlow:ValidateRequest",
                "BuyFlow:FiatUpdateBalance",
                "BuyFlow:SubmitCaasBuyRequest",
                "BuyFlow:CaasCallbackReceived",
                "BuyFlow:CryptoUpdateBalance",
                "BuyFlow:CaasBuyCryptoNotification",
                "BuyFlow:Notification",
                "BuyFlow:Processing",
                "SellFlow:InitiateRequest",
                "SellFlow:ValidateRequest",
                "SellFlow:FiatUpdateBalance",
                "SellFlow:SubmitCaasSellRequest",
                "SellFlow:CaasCallbackReceived",
                "SellFlow:CryptoUpdateBalance",
                "SellFlow:CaasSellCryptoNotification",
                "SellFlow:Notification",
                "SellFlow:Processing",
                "SendFlow:InitiateRequest",
                "SendFlow:ValidateRequest",
                "SendFlow:UpdateCryptoBalance",
                "SendFlow:SubmitCaasSendRequest",
                "SendFlow:CaasCallbackReceived",
                "SendFlow:CaasSendCryptoNotification",
                "SendFlow:Notification",
                "SendFlow:Processing",
                "ReceiveFlow:InitiateRequest",
                "ReceiveFlow:CryptoUpdateBalance",
                "ReceiveFlow:CaasReceiveCryptoNotification",
                "ReceiveFlow:Notification",
                "ExchangeFlow:InitiateRequest",
                "ExchangeFlow:ValidateRequest",
                "ExchangeFlow:UpdateFromCryptoBalance",
                "ExchangeFlow:UpdateToCryptoBalance",
                "ExchangeFlow:SubmitCaasExchangeRequest",
                "ExchangeFlow:CaasCallbackReceived",
                "ExchangeFlow:CaasExchangeCryptoNotification",
                "ExchangeFlow:Notification",
                "ExchangeFlow:Processing",
            ];
        case 'Error':
            return [
                'PrivateClient:ErrorEncountered',
                'BuyFlow:ErrorEncountered',
                'SellFlow:ErrorEncountered',
                'SendFlow:ErrorEncountered',
                'ReceiveFlow:ErrorEncountered',
                'ExchangeFlow:ErrorEncountered'
            ];
        case 'Rejected':
            return [
                'PrivateClient:Rejected',
                'BuyFlow:TransactionRejected',
                'SellFlow:TransactionRejected',
                'SendFlow:TransactionRejected',
                'ReceiveFlow:TransactionRejected',
                'ExchangeFlow:TransactionRejected',
            ];
        default:
            return [];
    }
}


export function getAvailableCurrencies(data) {
    const options = [
        {
            text: 'USDT',
            img: `/common/usdt.png`,
        },

        {
            text: 'DAI',
            img: `/common/crytoicons/dai.png`,
        },
        {
            text: 'USDC',
            img: `/common/crytoicons/usd.png`,
        },
        {
            text: 'ETH',
            img: `/common/crytoicons/eth1.png`,
        },
        {
            text: 'LTC',
            img: `/common/crytoicons/ltc.png`,
        },
        {
            text: 'XRP',
            img: `/common/crytoicons/xrp.png`,
        },
        {
            text: 'BTC',
            img: `/common/crytoicons/btc.png`,
        },
        {
            text: 'TRX',
            img: `/common/crytoicons/trx.png`,
        },
    ]
    const currencies = _.uniq(
        data.map((x) =>
            x.transactionDetails[0].currency
                ? x.transactionDetails[0].currency
                : x.transactionDetails[0].cryptoId,
        ),
    )
    if (currencies.length > 0) {
        const res = currencies.map((x) => {
            const option = options.find((option) => option.text === x)
            return {
                text: x,
                img: option.img,
            }
        })
        return res
    }
    return []
}

export function getAvailableStatuses(data) {
    const statuses = _.uniq(data.map((x) => x.status.code))
    const res = statuses.map((x) => {
        return {
            text: x,
        }
    })
    return res
}

export function getAvailableTypes(data) {
    const types = _.uniq(data.map((x) => x.type))
    const res = types.map((x) => {
        return {
            text: x,
        }
    })
    return res
}

export function toFixedTrunc(x, n = 2) {
    let xFixed = x || 0

    if (xFixed.toString().includes('e')) {
        xFixed = xFixed.toLocaleString('fullwide', { useGrouping: false })
    }

    if (xFixed === 0) {
        return xFixed
    }
    const v = (typeof xFixed === 'string' ? xFixed : xFixed.toString()).split(
        '.',
    )

    if (n <= 0) {
        return v[0]
    }
    let f = v[1] || ''
    let formatV = v[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    if (f.length > n) {
        return `${formatV}.${f.substr(0, n)}`
    }
    if (f === '' && (n === 2 || n === 4 || n === 6)) {
        return `${formatV}.${'0'.repeat(n)}`
    }
    if (f.length === 1) {
        f = f + '0'
    }

    if (f.length === 0) {
        return `${formatV}`
    } else {
        return `${formatV}.${f}`
    }
}

export const cryptoCurrencyList = [
    {
        id: 2,
        label: 'EUR',
        value: 'EUR',
        image: '/common/crytoicons/euro.png',
    },
    {
        id: 3,
        label: 'USD',
        value: 'USD',
        image: '/common/crytoicons/usa.png',
    },
    {
        id: 4,
        label: 'GBP',
        value: 'GBP',
        image: '/common/crytoicons/uk.png',
    },
    {
        id: 5,
        label: 'AUD',
        value: 'AUD',
        image: '/common/crytoicons/aud.png',
    },
    {
        id: 6,
        label: 'CAD',
        value: 'CAD',
        image: '/common/crytoicons/cad.png',
    },
    {
        id: 7,
        label: 'AED',
        value: 'AED',
        image: '/common/crytoicons/aed.png',
    },
    {
        image: '/common/crytoicons/chf.png',
        id: 8,
        label: 'CHF',
        value: 'CHF',
    },
    {
        image: '/common/crytoicons/try.png',
        id: 9,
        label: 'TRY',
        value: 'TRY',
    },
    {
        image: '/common/crytoicons/hkd.png',
        id: 10,
        label: 'HKD',
        value: 'HKD',
    },
    {
        image: '/common/crytoicons/sgd.png',
        id: 11,
        label: 'SGD',
        value: 'SGD',
    },
]

export const formatFileSize = function (bytes) {
    const sufixes = ['B', 'kB', 'MB', 'GB', 'TB']
    const i = Math.floor(Math.log(bytes) / Math.log(1024))
    return `${(bytes / Math.pow(1024, i)).toFixed(2)} ${sufixes[i]}`
}

export function clearAllValuesInObj(obj) {
    let newFormData = {}
    for (const key in obj) {
        newFormData[key] = ''
    }
    return newFormData
}

export function getClientTransactionUrl(api_url) {
    return (
        (window.location.hostname === 'localhost' ? api_url : '') +
        '/restapi/create-client-transaction-merchant'
    )
}

export const numberRule = /^(\d+)((.{1}\d+)?)$/
export const numberRuleZero = /^(?:0)[0-9]/
export const testEmailRule = (v) =>
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        v,
    ) || false


export function validateInputAmount(value) {
    return value
        .replace(/[^0-9.]/g, '')
        .replace(/^0+(?=\d)/, '')
        .replace(/(\..*?)\..*/g, '$1');
}

export function validateAmount(value, fee, selectedCurrency, balancesData) {    
    let formattedValue = value && Number(value).toFixed(10)
    
    if (selectedCurrency && selectedCurrency[0]) {
        const balance = balancesData.find(
            (x) => x.currencyShortName === (Array.isArray(selectedCurrency) ? selectedCurrency[0] : selectedCurrency)
        );

        const transactionFee = fee ? (Number(formattedValue) * fee.percentFee) / 100 : 0
        const finalBalance = Number(formattedValue) + transactionFee

        if (!formattedValue) {
            return '* Amount field is required'
        }
        if (numberRuleZero.test(formattedValue)) {
            return '* Please, fill the amount value correctly'
        }        
        if (!numberRule.test(formattedValue)) {
            return '* Please, fill the amount value correctly'
        }
        if (Number(formattedValue) <= 0) {
            return '* Amount must be greater than 0'
        }
        if (balance.balanceAmount === 0) {
            return "* Please adjust the value as you have an insufficient balance"
        }
        if (formattedValue.includes(',')) {
            return '* Please use only numbers without commas'
        }
        if (balance && (finalBalance > balance.balanceAmount)) {
            return transactionFee !== 0 ? "* Please adjust the value to take into account any fees payable for this transaction" : "* Please adjust the value as you have an insufficient balance"
        } else {
            return ''
        }
    }
}

export function validateCurrency(selectedCurrency, balancesData) {
    if (selectedCurrency && selectedCurrency[0]) {
        const balance = balancesData.find(
            (x) => x.currencyShortName === (Array.isArray(selectedCurrency) ? selectedCurrency[0] : selectedCurrency)
        );

        if (balance.isFreeze) {
            return "* This currency is temporarily unavailable. Please contact your administrator for further assistance."
        }
        else {
            return ''
        }
    } else if (!selectedCurrency || selectedCurrency.length === 0 || !selectedCurrency[0]) {
        return '* Currency field is required'
    }
}

export const handleCryptoAmountValidation = (cryptoAmount, selectedCurrency, balancesCrypto) => {
    const balance = balancesCrypto.find(
        (x) => x.currencyShortName === selectedCurrency
    );

    if (balance) {
        if (cryptoAmount < balance.minTradeAmt || cryptoAmount > balance.maxTradeAmt) {
             return `*${selectedCurrency} amount must be between ${balance.minTradeAmt} and ${balance.maxTradeAmt}.`
        }
    }
    return "";
};

export function removeCommas(input) {
    return typeof input === 'string' ? input.replace(/,/g, '') : input;
}

export function validateEmail(email, clientEmail) {
    if (!testEmailRule(email)) {
        return '* Please enter a valid email'
    }
    if (email === clientEmail) {
        return "* You can't make a transaction to your email. Please, use a valid email"
    } else {
        return ''
    }
}

export const renderExpandedComponent = (data, expandName) => {
    const value = data.type || expandName
    switch (value) {
        case 'Deposit':
            return <DepositExpanded {...data} />
        case 'Withdrawal':
            return <WithdrawalExpanded {...data} />
        case 'Payment Out':
            return <PaymentOutExpanded {...data} />
        case 'Transfer':
            return <TransferExpanded {...data} />
        case 'FX':
            return <FxExpanded {...data} />
        case 'bank-accounts':
            return <AccountsExpanded data={data} />
        default:
            return <div>No data</div>
    }
}

export const renderExpandedCryptoComponent = (data, expandName) => {
    const value = data.type || expandName
    switch (value) {
        case 'Buy':
            return <BuyCryptoExpanded {...data} />
        case 'Sell':
            return <SellCryptoExpanded {...data} />
        case 'Exchange':
            return <ExchangeExpanded {...data} />
        case 'Send':
            return <SendExpanded {...data} />
        case 'Receive':
            return <ReceiveExpanded {...data} />
        case 'bank-accounts':
            return <AccountsExpanded data={data} />
        default:
            return <div>No data</div>
    }
}

export function getDateTextPicker(selectedDate) {
    if (selectedDate) {
        if (selectedDate.from && !selectedDate.to) {
            return format(new Date(selectedDate.from), 'dd/MM/y')
        }
        if (selectedDate.from && selectedDate.to) {
            return `${format(new Date(selectedDate.from), 'dd/MM/y')} - ${format(new Date(selectedDate.to), 'dd/MM/y')}`
        } else {
            return 'Date'
        }
    } else {
        return 'Date'
    }
}
export const getBalanceSign = (data) => {
    const value = data.type
    switch (value) {
        case 'Deposit':
        case 'Receive':
        case 'Buy':
            return '+ '
        case 'Withdrawal':
        case 'Payment Out':
            return data?.txnInOut === 'In' ? '+ ' : '- '
        case 'Send':
        case 'Sell':
            return '- '
        case 'Transfer':
            return data.transferInOut === 'In' ? '+ ' : '- '
        default:
            return ''
    }
}

export const getExChangeAmount = (row, activeCurrencyPage) => {
    const formatAmount = (currency, amount, sign) => `${currency} ${sign} ${toFixedTrunc(amount, currency === "crypto" ? 6 : 2)}`;

    const transactionDetails = row?.transactionRef?.transactionDetails?.[0] || {};

    if (row?.type === "FX" && row?.amount && transactionDetails?.fromCurrency && transactionDetails?.toCurrency) {
        const amountDisplay = row.fxInOut === "Out"
            ? `${formatAmount(row.currency, row.amount, "-")}`
            : `${formatAmount(row.currency, row.amount, "+")}`;

        const fxDisplay = row.fxInOut === "Out"
            ? `${formatAmount(transactionDetails.toCurrency, transactionDetails.toAmount, "+")}`
            : `${formatAmount(transactionDetails.fromCurrency, transactionDetails.fromAmount, "-")}`;

        return `${amountDisplay}${fxDisplay}\n`;

    } else if (row?.type === "Buy") {
        return `${formatAmount(transactionDetails.fiatCurrency, transactionDetails.fiatAmount, "-")}${formatAmount(transactionDetails.cryptoId, transactionDetails.cryptoAmount, "+")}`;

    } else if (row?.type === "Sell") {
        return `${formatAmount(transactionDetails.cryptoId, transactionDetails.cryptoAmount, "-")}${formatAmount(transactionDetails.fiatCurrency, transactionDetails.fiatAmount, "+")}`;

    } else if (row?.type === "Exchange") {
        return `${formatAmount(transactionDetails.fromCryptoId, transactionDetails.fromCryptoAmount, "-")}${formatAmount(transactionDetails.toCryptoId, transactionDetails.toCryptoAmount, "+")}`;
    }

    return `${getBalanceSign(row)} ${getCurrencySymbol(row.currency)} ${toFixedTrunc(
        row.amount,
        activeCurrencyPage === "crypto" ? 6 : 2
    )}`;
};

export const getTransectionDetails = (row) => {
    if (!row || !row.transactionRef) return row.transactionId || "N/A";

    const transactionDetails = row.transactionRef.transactionDetails?.[0] || {};
    let statusHtml = "";
    const { type, transactionRef } = row;

    if (
        transactionRef.status?.code === "PrivateClient:RequestForApproval" &&
        (type === "Withdrawal" || type === "Payment Out")
    ) {
        statusHtml = 'Status : Processing';
    } else if (transactionRef.status?.code === "PrivateClient:Rejected" && row?.txnInOut === "In") {
        statusHtml = `Cancelled : ${transactionRef.status.message}`;
    }

    const getCurrency = (currency, cryptoId) => currency || cryptoId || "N/A";

    const commonRender = (from, to) => `from : ${from} - to : ${to}`;

    switch (type) {
        case "Deposit":
            return `${transactionRef.currencyType === "crypto"
                ? commonRender(transactionDetails.fromAddress, transactionDetails.toAddress)
                : transactionDetails.remitterName || "N/A"
                }\nCurrency ${getCurrency(row.currency, transactionDetails.cryptoId)}
			`;

        case "Transfer":
            return `Name : ${transactionDetails.name || "N/A"}\nCurrency ${transactionDetails.currency || "N/A"}`;

        case "Withdrawal":
        case "Payment Out":
            return `from : ${transactionDetails.beneficiaryName || "N/A"}\nCurrency ${getCurrency(transactionDetails.currency, transactionDetails.cryptoId)}\n${statusHtml}`;

        case "FX":
            return `${commonRender(transactionDetails.fromCurrency, transactionDetails.toCurrency)}\nFX Rates ${toFixedTrunc(transactionDetails.fxrate, 4) || "N/A"}`;

        case "Buy":
            return commonRender(transactionDetails.fiatCurrency, transactionDetails.cryptoId);

        case "Sell":
            return commonRender(transactionDetails.cryptoId, transactionDetails.fiatCurrency);

        case "Exchange":
            return commonRender(transactionDetails.fromCryptoId, transactionDetails.toCryptoId);

        case "Send":
        case "Receive":
            return `Currency : ${transactionDetails.cryptoId || "N/A"}`;

        default:
            return `${row.transactionId || "N/A"}`;
    }
};

export const useJunoCommonFunctions = () => {
    const dispatch = useDispatch()
    const clientData = useSelector((state) => state.juno.clientData)
    const balancesData = useSelector((state) => state.juno.balancesCurrencies)
    const clientTransactionFee = useSelector(
        (state) => state.juno.clientTransactionFee
    )
    const api_url = useSelector((state) => state.config.api_url)
    const activeCurrencyPage = useSelector((state) => state.juno.activeCurrencyPage)
    const imageBaseUrl = useSelector((state) => state.config.image_base_url)

    const getBalances = () => {
        return new Promise((resolve, reject) => {
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/get-client-balances',
                {
                    clientId: clientData.clientId,
                },
                async (data) => {
                    if (data) {
                        dispatch(actions.setBalancesData(data));
                        resolve(data.clientBalanceList);
                    }
                },
                (err) => {
                    console.log(err);
                    reject(err);
                }
            );
        });
    };

    const getCountries = () => {
        return new Promise((resolve, reject) => {
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/get-countries',
                {
                    fetchAll: true
                },
                async (data) => {
                    resolve(data)
                },
                (err) => {
                    console.log(err);
                    reject(err);
                }
            );
        });
    };

    const isRepeatAllowed = async (row) => {
        const balances = await getBalances()
        const unfreezeBalances = balances.filter(balance => !balance.isFreeze)
        return row.type === "FX"
            ? unfreezeBalances.some(currency => currency.currencyShortName === row?.transactionDetails[0]?.fromCurrency)
            && unfreezeBalances.some(currency => currency.currencyShortName === row?.transactionDetails[0]?.toCurrency)
            : unfreezeBalances.some(currency => currency.currencyShortName === row?.currency)

    }

    const getCryptoFee = (feeData) => {
        return new Promise((resolve, reject) => {
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/get-crypto-fee',
                feeData,
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                },
            );
        });
    };

    const getCryptoBalances = () => {
        return new Promise((resolve, reject) => {
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/get-crypto-balance',
                {
                    clientId: clientData.clientId,
                },
                async (data) => {
                    if (data) {
                        dispatch(actions.setCryptoBalancesData(data))
                        resolve(data.clientBalanceList)
                    }
                },
                (err) => {
                    console.log(err)
                    reject(err)
                }
            )
        })
    }

    const getSecretKey = () => {
        return new Promise((resolve, reject) => {
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/generate-2fa-secreat-code',
                {
                    userEmail: localStorage.getItem('user_name'),
                    hostName: 'junoMoney',
                },
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                },
            );
        });
    };

    const getSavedAccounts = () => {
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
            '/restapi/get-client-beneficiary',
            {},
            async (data) => {
                if (data) {
                    dispatch(actions.setAccountsData(data))
                }
            },
            (err) => {
                console.log(err)
            },
        )
    }

    const useWindowWidth = () => {
        const [size, setSize] = useState(0)
        useLayoutEffect(() => {
            function updateSize() {
                setSize(window.innerWidth)
            }
            window.addEventListener('resize', updateSize)
            updateSize()
            return () => window.removeEventListener('resize', updateSize)
        }, [])
        return size
    }

    const saveAccountApi = (formData, onSuccess, onError, setFailedErr) => {
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
            '/restapi/save-client-beneficiary',
            {
                client: clientData._id,
                accountNickName: formData.accountNickName,
                beneficiaryName: formData.beneficiaryName,
                beneficiaryAccountNumber: formData.beneficiaryAccountNumber,
                beneficiaryAddress: formData.beneficiaryAddress,
                bankName: formData.bankName,
                bankAddress: formData.bankAddress,
                iban: formData.iban,
                swift: formData.swift,
                additionalBankDetails: formData.additionalBankDetails,
                reference: formData.reference,
                sortCode: formData.sortCode,
                currency: formData.currency[0],
                bankCountry: formData.bankCountry,
                beneficiaryCountry: formData.beneficiaryCountry,
            },
            async (data) => {
                if (data) {
                    onSuccess()
                }
            },
            (err) => {
                console.log(err)
                onError()
                setFailedErr(err)
            },
        )
    }

    const updateAccountApi = (formData, onSuccess, onError) => {
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
            '/restapi/update-client-beneficiary',
            {
                client: clientData._id,
                beneficiaryId: formData._id,
                accountNickName: formData.accountNickName,
                beneficiaryName: formData.beneficiaryName,
                beneficiaryAccountNumber: formData.beneficiaryAccountNumber,
                beneficiaryAddress: formData.beneficiaryAddress,
                bankName: formData.bankName,
                bankAddress: formData.bankAddress,
                iban: formData.iban,
                swift: formData.swift,
                additionalBankDetails: formData.additionalBankDetails,
                reference: formData.reference,
                sortCode: formData.sortCode,
                currency: formData.currency[0],
                bankCountry: formData.bankCountry,
                beneficiaryCountry: formData.beneficiaryCountry,
            },

            async (data) => {
                if (data) {
                    onSuccess(false)
                }
            },
            (err) => {
                console.log(err)
                onError()
            },
        )
    }

    const getTransactions = () => {
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
            '/restapi/get-client-transactions',
            {
                client: clientData.clientId,
                currencyType: activeCurrencyPage
            },
            async (data) => {
                if (data) {
                    dispatch(
                        actions.setTransactionsData(
                            data.clientsTransactionData,
                        ),
                    )
                }
            },
            (err) => {
                console.log(err)
            },
        )
    }

    const getStatements = async () => {
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
            '/restapi/get-client-statement',
            {
                clientId: clientData.clientId,
                currencyType: activeCurrencyPage
            },
            async (data) => {
                if (data) {
                    dispatch(actions.setStatementsData(data))
                }
            },
            (err) => {
                console.log(err)
            },
        )
    }

    const getWhitelistData = () => {
        return new Promise((resolve, reject) => {
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/get-all-juno-white-listing',
                { filterParams: { limit: 100 } },
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                },
            );
        });
    };

    const handleCryptoExchangeRate = (exchangeData) => {
        return new Promise((resolve, reject) => {
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/crypto-exchange-rate',
                exchangeData,
                (data) => {
                    resolve(data);
                },
                (err) => {
                    reject(err);
                },
            );
        });
    }

    function getCryptoIcons(type) {
        switch (type) {
            case 'Buy':
                return (
                    <div className="w-10 h-10 flex justify-center items-center bg-[#EAECF0] rounded-full lg:mr-[10px]">
                        <img
                            className="w-[20px] h-[20px]"
                            src={`${imageBaseUrl}/adminUi/buy-crypto.svg`}
                            alt="buyCrypto"
                        />
                    </div>
                )
            case 'Sell':
                return (
                    <div className="w-10 h-10 flex justify-center items-center bg-[#EAECF0] rounded-full lg:mr-[10px]">
                        <img
                            src={`${imageBaseUrl}/adminUi/sell-crypto.svg`}
                            alt="sellCrypto"
                        />
                    </div>
                )
            case 'Exchange':
                return <Icon id="fx" />
            case 'Send':
                return <Icon id="transfer" />
            case 'Receive':
                return <Icon id="deposit" />
        }
    }

    const getClientFeeSetting = (payload) => {
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
            '/restapi/get-client-fee-setting',
            payload,
            async (data) => {
                if (data) {
                    dispatch(actions.setClientTransferFee(data))
                }
            },
            (err) => {
                console.log(err)
            }
        )
    }

    const getFeeForCurrency = useCallback(
        (from, to) => {
            const clientFXFee = clientTransactionFee.find(
                (e) => e.currency === from
            )
            if (clientFXFee?.fees) {
                return clientFXFee.fees.find((fee) => fee?.toCurrency === to)
            }
            return null
        },
        [clientTransactionFee]
    )

    const sendNotification = (transactionId, paymentProviderId) => {
        apiService(
            (window.location.hostname === 'localhost' ? api_url : '') +
            '/restapi/add-notification',
            {
                type: 'transaction-request',
                priority: 'high',
                status: 'unread',
                message: 'New Payment Out/Withdrawal Request submitted',
                relatedTransactionId: transactionId,
                paymentProviderId: paymentProviderId,
                data: {},
            },
            async (data) => {
                if (data) {
                    return data;
                }
            },
            (err) => {
                console.log(err)
                dispatch(setErrorMessage("Add notification" + err))
            }
        )
    }

    const validateFreezedCurrencies = (modalType, onCheck) => {
        let unfreezeBalances = [];

        if (balancesData) {
            unfreezeBalances = balancesData.filter(balance => !balance.isFreeze);
        }

        if (
            unfreezeBalances.length === 0
        ) {
            dispatch(setErrorMessage(
                "There are no available currencies at the moment. Please contact your administrator for further assistance."
            ));
            return;
        } else if ((unfreezeBalances.length <= 1 && ["fx", "FX"].includes(modalType))) {
            dispatch(setErrorMessage(
                "Currency exchange requires multiple available currencies. Please contact your administrator to add another currency and proceed with the FX transaction."
            ));
            return
        }
        onCheck()
    }

    const getManagedCurrencies = (clientId) => {
        return new Promise((resolve, reject) => {
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/get-managed-currencies',
                {
                    clientId,
                },
                async (data) => {
                    if (data?.currencies) {
                        dispatch(actions.setManagedCurrencies(data?.currencies));
                        resolve(data);
                    }
                },
                (err) => {
                    console.log(err);
                    reject(err);
                }
            );
        });
    };

    const updateManagedCurrencies = (clientId, currencies) => {
        return new Promise((resolve, reject) => {
            apiService(
                (window.location.hostname === 'localhost' ? api_url : '') +
                '/restapi/update-managed-currencies',
                {
                    clientId,
                    currencies
                },
                async (data) => {
                    if (data) {
                        dispatch(actions.setManagedCurrencies(data?.currencies));
                        resolve(data);
                    }
                },
                (err) => {
                    console.log(err);
                    reject(err);
                }
            );
        });
    };

    return {
        getWhitelistData,
        getBalances,
        getSavedAccounts,
        useWindowWidth,
        saveAccountApi,
        updateAccountApi,
        getTransactions,
        getStatements,
        getSecretKey,
        getCryptoBalances,
        handleCryptoExchangeRate,
        getCryptoFee,
        getCryptoIcons,
        sendNotification,
        getClientFeeSetting,
        getCountries,
        getFeeForCurrency,
        validateFreezedCurrencies,
        getManagedCurrencies,
        updateManagedCurrencies
    }

}


export const defaultCurrenciesToDisplay = ['USD', 'EUR', 'GBP', "BTC", "ETH", "LTC"]

export function getShortName(fullName) {
    if (!fullName) return
    const splittedName = fullName.split(' ')
    if (splittedName.length < 2) {
        return splittedName[0].charAt(0) + splittedName[0].charAt(1)
    }
    const shortName = splittedName[0].charAt(0) + splittedName[1].charAt(0)
    return shortName
}


export const MenuItems = [
    {
        id: 1,
        title: "Home",
        path: "/client",
        icon: <svg
            data-e2e="home-tab"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M4 11.4522V16.8002C4 17.9203 4 18.4807 4.21799 18.9086C4.40973 19.2849 4.71547 19.5906 5.0918 19.7823C5.5192 20.0001 6.07899 20.0001 7.19691 20.0001H16.8031C17.921 20.0001 18.48 20.0001 18.9074 19.7823C19.2837 19.5906 19.5905 19.2849 19.7822 18.9086C20 18.4811 20 17.9216 20 16.8037V11.4522C20 10.9179 19.9995 10.6506 19.9346 10.4019C19.877 10.1816 19.7825 9.97307 19.6546 9.78464C19.5102 9.57201 19.3096 9.39569 18.9074 9.04383L14.1074 4.84383C13.3608 4.19054 12.9875 3.86406 12.5674 3.73982C12.1972 3.63035 11.8026 3.63035 11.4324 3.73982C11.0126 3.86397 10.6398 4.19014 9.89436 4.84244L5.09277 9.04383C4.69064 9.39569 4.49004 9.57201 4.3457 9.78464C4.21779 9.97307 4.12255 10.1816 4.06497 10.4019C4 10.6506 4 10.9179 4 11.4522Z"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>

    },
    {
        id: 2,
        title: "Balances",
        path: "/client/balances",
        icon: <svg
            data-e2e="balances-tab"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="19"
            viewBox="0 0 20 19"
            fill="none"
        >
            <path
                d="M17 18H3C2.46957 18 1.96086 17.7893 1.58579 17.4142C1.21071 17.0391 1 16.5304 1 16V7C1 6.46957 1.21071 5.96086 1.58579 5.58579C1.96086 5.21071 2.46957 5 3 5H17C17.5304 5 18.0391 5.21071 18.4142 5.58579C18.7893 5.96086 19 6.46957 19 7V16C19 16.5304 18.7893 17.0391 18.4142 17.4142C18.0391 17.7893 17.5304 18 17 18Z"
                stroke="#344054"
                strokeWidth="1.5"
            />
            <path
                d="M14.5 12C14.3674 12 14.2402 11.9473 14.1464 11.8536C14.0527 11.7598 14 11.6326 14 11.5C14 11.3674 14.0527 11.2402 14.1464 11.1464C14.2402 11.0527 14.3674 11 14.5 11C14.6326 11 14.7598 11.0527 14.8536 11.1464C14.9473 11.2402 15 11.3674 15 11.5C15 11.6326 14.9473 11.7598 14.8536 11.8536C14.7598 11.9473 14.6326 12 14.5 12Z"
                fill="#344054"
                stroke="#344054"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M16 4.99996V3.60296C15.9999 3.29646 15.9294 2.99408 15.7939 2.71918C15.6583 2.44427 15.4614 2.20419 15.2184 2.01747C14.9753 1.83075 14.6926 1.7024 14.3921 1.64232C14.0915 1.58224 13.7812 1.59204 13.485 1.67096L2.485 4.60396C2.05905 4.71747 1.68254 4.96852 1.41399 5.31809C1.14544 5.66765 0.999902 6.09615 1 6.53696V6.99996"
                stroke="#344054"
                strokeWidth="1.5"
            />
        </svg>

    },
    {
        id: 3,
        title: "Accounts",
        path: "/client/accounts",
        icon: <svg
            data-e2e="accounts-tab"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                className="fill"
                d="M6.5 10H4.5V17H6.5V10ZM12.5 10H10.5V17H12.5V10ZM21 19H2V21H21V19ZM18.5 10H16.5V17H18.5V10ZM11.5 3.26L16.71 6H6.29L11.5 3.26ZM11.5 1L2 6V8H21V6L11.5 1Z"
                fill="#344054"
            />
        </svg>

    },
    {
        id: 4,
        title: "Transaction history",
        path: "/client/transactions",
        icon: <svg
            data-e2e="transacrion-history-tab"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M4.266 16.06C5.11205 17.7705 6.4842 19.1647 8.181 20.038C9.86564 20.903 11.7864 21.1951 13.652 20.87C15.5234 20.541 17.2379 19.6148 18.539 18.23C19.8521 16.8321 20.6882 15.0539 20.927 13.151C21.1696 11.2437 20.8043 9.30851 19.883 7.62098C18.9703 5.9463 17.544 4.60898 15.814 3.80598C14.0957 3.01014 12.1646 2.79667 10.314 3.19798C8.464 3.59898 6.948 4.51098 5.694 5.95298C5.543 6.11298 4.959 6.75898 4.474 7.73398M7.5 7.99998L3.891 8.71998L3 4.99998M12 8.99998V13L15 15"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>

    },
    {
        id: 5,
        title: "Profile",
        path: "/client/profile",
        icon: <svg
            data-e2e="profile-tab"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                className="fill"
                d="M16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10C8 8.93913 8.42143 7.92172 9.17157 7.17157C9.92172 6.42143 10.9391 6 12 6C13.0609 6 14.0783 6.42143 14.8284 7.17157C15.5786 7.92172 16 8.93913 16 10Z"
                fill="#344054"
            />
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.592 21.992C6.2585 21.778 2 17.386 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C11.9543 22.0003 11.9087 22.0003 11.863 22C11.7725 22 11.682 21.997 11.592 21.992ZM5.583 18.31C5.50823 18.0953 5.48278 17.8665 5.50854 17.6406C5.5343 17.4147 5.6106 17.1975 5.73179 17.0051C5.85298 16.8128 6.01593 16.6502 6.20856 16.5294C6.40119 16.4086 6.61855 16.3328 6.8445 16.3075C10.7425 15.876 13.2815 15.915 17.1605 16.3165C17.3868 16.3401 17.6046 16.4149 17.7976 16.5354C17.9905 16.6559 18.1534 16.8189 18.2739 17.0119C18.3943 17.2048 18.4691 17.4227 18.4927 17.649C18.5162 17.8753 18.4877 18.1039 18.4095 18.3175C20.072 16.6354 21.003 14.365 21 12C21 7.0295 16.9705 3 12 3C7.0295 3 3 7.0295 3 12C3 14.458 3.9855 16.686 5.583 18.31Z"
                fill="#344054"
            />
        </svg>

    },
]



export const cryptoMenuItems = [
    {
        id: 1,
        title: "Home",
        path: "/client",
        icon: <svg
            data-e2e="home-tab"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M4 11.4522V16.8002C4 17.9203 4 18.4807 4.21799 18.9086C4.40973 19.2849 4.71547 19.5906 5.0918 19.7823C5.5192 20.0001 6.07899 20.0001 7.19691 20.0001H16.8031C17.921 20.0001 18.48 20.0001 18.9074 19.7823C19.2837 19.5906 19.5905 19.2849 19.7822 18.9086C20 18.4811 20 17.9216 20 16.8037V11.4522C20 10.9179 19.9995 10.6506 19.9346 10.4019C19.877 10.1816 19.7825 9.97307 19.6546 9.78464C19.5102 9.57201 19.3096 9.39569 18.9074 9.04383L14.1074 4.84383C13.3608 4.19054 12.9875 3.86406 12.5674 3.73982C12.1972 3.63035 11.8026 3.63035 11.4324 3.73982C11.0126 3.86397 10.6398 4.19014 9.89436 4.84244L5.09277 9.04383C4.69064 9.39569 4.49004 9.57201 4.3457 9.78464C4.21779 9.97307 4.12255 10.1816 4.06497 10.4019C4 10.6506 4 10.9179 4 11.4522Z"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    },
    {
        id: 2,
        title: "Transaction history",
        path: "/client/transactions",
        icon: <svg
            data-e2e="transacrion-history-tab"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                d="M4.266 16.06C5.11205 17.7705 6.4842 19.1647 8.181 20.038C9.86564 20.903 11.7864 21.1951 13.652 20.87C15.5234 20.541 17.2379 19.6148 18.539 18.23C19.8521 16.8321 20.6882 15.0539 20.927 13.151C21.1696 11.2437 20.8043 9.30851 19.883 7.62098C18.9703 5.9463 17.544 4.60898 15.814 3.80598C14.0957 3.01014 12.1646 2.79667 10.314 3.19798C8.464 3.59898 6.948 4.51098 5.694 5.95298C5.543 6.11298 4.959 6.75898 4.474 7.73398M7.5 7.99998L3.891 8.71998L3 4.99998M12 8.99998V13L15 15"
                stroke="#344054"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>

    },
    {
        id: 3,
        title: "Address",
        path: "/client/address",
        icon: <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.69236 11.9002L9.69212 11.9002L8.99979 12.6971H8.89979V12.6973C8.89979 12.6974 8.89979 12.6974 8.89979 12.6974L8.89979 13.1608C8.89979 13.6769 9.25483 14.1105 9.80602 14.4097C10.3589 14.7097 11.1263 14.8859 11.9998 14.8859C12.8732 14.8859 13.6407 14.7097 14.1936 14.4096C14.7448 14.1103 15.0998 13.6765 15.0998 13.1599L15.0998 12.6974L14.9998 12.6971H15.0998V12.6972C15.1001 12.5929 15.0799 12.4895 15.0404 12.393C15.0007 12.2964 14.9425 12.2086 14.8689 12.1346C14.7953 12.0605 14.7078 12.0017 14.6115 11.9615C14.5152 11.9213 14.4119 11.9005 14.3075 11.9002H14.3072H9.69236ZM13.2837 10.7101C13.6238 10.3694 13.8146 9.90752 13.8141 9.42611C13.8136 8.9447 13.6219 8.48321 13.2812 8.14314C12.9404 7.80308 12.4785 7.6123 11.9971 7.61278C11.5157 7.61326 11.0542 7.80496 10.7142 8.14571C10.5458 8.31443 10.4123 8.51466 10.3213 8.73497C10.2303 8.95529 10.1836 9.19137 10.1838 9.42974C10.1843 9.91114 10.376 10.3726 10.7167 10.7127C11.0575 11.0528 11.5193 11.2435 12.0007 11.2431C12.4822 11.2426 12.9437 11.0509 13.2837 10.7101ZM6.64265 2.04307C5.87731 2.04307 5.14332 2.3471 4.60214 2.88827C4.06096 3.42945 3.75693 4.16344 3.75693 4.92878V19.0716C3.75693 19.837 4.06096 20.571 4.60214 21.1121C5.14332 21.6533 5.87731 21.9574 6.64265 21.9574H19.4998C19.6968 21.9574 19.8858 21.8791 20.0251 21.7398C20.1644 21.6005 20.2426 21.4115 20.2426 21.2145C20.2426 21.0175 20.1644 20.8285 20.0251 20.6892C19.8858 20.5499 19.6968 20.4716 19.4998 20.4716H6.64265C6.30601 20.4716 5.98064 20.3503 5.72616 20.1299C5.50109 19.935 5.34481 19.6741 5.27842 19.3859H19.4998C19.6968 19.3859 19.8858 19.3077 20.0251 19.1683C20.1644 19.029 20.2426 18.8401 20.2426 18.6431V4.92878C20.2426 4.16344 19.9386 3.42945 19.3974 2.88827C18.8563 2.3471 18.1223 2.04307 17.3569 2.04307H6.64265ZM18.7569 4.92878V17.9002H5.24265V4.92878C5.24265 4.15601 5.86988 3.52878 6.64265 3.52878H17.3569C18.1297 3.52878 18.7569 4.15601 18.7569 4.92878Z" fill="#18181B" stroke="#18181B" stroke-width="0.2" />
        </svg>
    },
    {
        id: 4,
        title: "Profile",
        path: "/client/profile",
        icon: <svg
            data-e2e="profile-tab"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
        >
            <path
                className="fill"
                d="M16 10C16 11.0609 15.5786 12.0783 14.8284 12.8284C14.0783 13.5786 13.0609 14 12 14C10.9391 14 9.92172 13.5786 9.17157 12.8284C8.42143 12.0783 8 11.0609 8 10C8 8.93913 8.42143 7.92172 9.17157 7.17157C9.92172 6.42143 10.9391 6 12 6C13.0609 6 14.0783 6.42143 14.8284 7.17157C15.5786 7.92172 16 8.93913 16 10Z"
                fill="#344054"
            />
            <path
                className="fill"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.592 21.992C6.2585 21.778 2 17.386 2 12C2 6.477 6.477 2 12 2C17.523 2 22 6.477 22 12C22 17.523 17.523 22 12 22C11.9543 22.0003 11.9087 22.0003 11.863 22C11.7725 22 11.682 21.997 11.592 21.992ZM5.583 18.31C5.50823 18.0953 5.48278 17.8665 5.50854 17.6406C5.5343 17.4147 5.6106 17.1975 5.73179 17.0051C5.85298 16.8128 6.01593 16.6502 6.20856 16.5294C6.40119 16.4086 6.61855 16.3328 6.8445 16.3075C10.7425 15.876 13.2815 15.915 17.1605 16.3165C17.3868 16.3401 17.6046 16.4149 17.7976 16.5354C17.9905 16.6559 18.1534 16.8189 18.2739 17.0119C18.3943 17.2048 18.4691 17.4227 18.4927 17.649C18.5162 17.8753 18.4877 18.1039 18.4095 18.3175C20.072 16.6354 21.003 14.365 21 12C21 7.0295 16.9705 3 12 3C7.0295 3 3 7.0295 3 12C3 14.458 3.9855 16.686 5.583 18.31Z"
                fill="#344054"
            />
        </svg>

    },
]


const processingStatuses = new Set([
    'BuyFlow:InitiateRequest',
    'BuyFlow:ValidateRequest',
    'BuyFlow:FiatUpdateBalance',
    'BuyFlow:SubmitCaasBuyRequest',
    'BuyFlow:CaasCallbackReceived',
    'BuyFlow:CryptoUpdateBalance',
    'BuyFlow:Notification',
    'SellFlow:InitiateRequest',
    'SellFlow:ValidateRequest',
    'SellFlow:FiatUpdateBalance',
    'SellFlow:SubmitCaasBuyRequest',
    'SellFlow:CaasCallbackReceived',
    'SellFlow:CryptoUpdateBalance',
    'SellFlow:Notification',
    'SendFlow:InitiateRequest',
    'SendFlow:ValidateRequest',
    'SendFlow:UpdateCryptoBalance',
    'SendFlow:SubmitCaasBuyRequest',
    'SendFlow:CaasCallbackReceived',
    'SendFlow:CaasSendCryptoNotification',
    'SendFlow:Notification',
    'ReceiveFlow:InitiateRequest',
    'ReceiveFlow:CryptoUpdateBalance',
    'ExchangeFlow:InitiateRequest',
    'ExchangeFlow:ValidateRequest',
    'ExchangeFlow:UpdateFromCryptoBalance',
    'ExchangeFlow:UpdateToCryptoBalance',
    'ExchangeFlow:SubmitCaasBuyRequest',
    'ExchangeFlow:CaasCallbackReceived',
    'ExchangeFlow:Notification',
    "SendFlow:Processing",
    "ExchangeFlow:Processing",
    "SellFlow:Processing",
    "BuyFlow:Processing",

]);

const successStatuses = new Set([
    'BuyFlow:BuyCompleted',
    'SellFlow:SellCompleted',
    'SendFlow:SendCompleted',
    'ReceiveFlow:ReceiveCompleted',
    'ExchangeFlow:ExchangeCompleted'
]);

const errorStatuses = new Set([
    'BuyFlow:ErrorEncountered',
    'SellFlow:ErrorEncountered',
    'SendFlow:ErrorEncountered',
    'ReceiveFlow:ErrorEncountered',
    'ExchangeFlow:ErrorEncountered'
]);
const rejectedStatuses = new Set([
    'BuyFlow:TransactionRejected',
    'SellFlow:TransactionRejected',
    'SendFlow:TransactionRejected',
    'ReceiveFlow:TransactionRejected',
    'ExchangeFlow:TransactionRejected'
]);

export function getCryptoStatus(statusName) {
    if (processingStatuses.has(statusName)) {
        return 'Processing';
    }
    if (successStatuses.has(statusName)) {
        return 'Success';
    }
    if (errorStatuses.has(statusName)) {
        return 'Error';
    }
    if (rejectedStatuses.has(statusName)) {
        return 'Rejected';
    }
    return 'N/A';
}

export const getSlicedAddress = (address) => {
    if (!address || typeof address !== "string") {
        return
    }
    return `${address.slice(0, 9)}...${address.slice(-9)}`;
};

export const convertToEndOfDayUTC = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const endOfDayUTC = new Date(Date.UTC(year, month, day, 23, 59, 59, 999));

    return endOfDayUTC.toISOString();
}

export const convertFromStartOfDayUTC = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const startOfDayUTC = new Date(Date.UTC(year, month, day, 0, 0, 0, 0));

    return startOfDayUTC.toISOString();
}

export const typeItems = [
    {
        value: 'Deposit',
        label: 'deposit',
    },
    {
        value: 'Withdrawal',
        label: 'withdraw',
    },
    {
        value: 'Payment Out',
        label: 'paymentOut',
    },
    {
        value: 'Transfer',
        label: 'transfer',
    },
    {
        value: 'FX',
        label: 'fx',
    },
    // {
    //     value: 'Merchants',
    //     label: 'merchantTransfer',
    // },
]
